/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


@font-face {
    font-family: 'regular';
    src: url(assets/fonts/Poppins-Regular.ttf);
}

@font-face {
    font-family: 'medium';
    src: url(assets/fonts/Poppins-Medium.ttf);
}

@font-face {
    font-family: 'semi-bold';
    src: url(assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
    font-family: 'bold';
    src: url(assets/fonts/Poppins-Bold.ttf);
}
*{
    font-family: 'regular';
    // color: white;
}

ion-label {
    color: lightgrey;
}

ion-header {
    ion-toolbar {
        --background: #2d2d39;

        ion-title {
            // font-family: 'medium';
            letter-spacing: 1px;
            color: lightgrey;
            font-size: 15px;
        }

        ion-button {
            --color: #d3d3d3;
            margin: 0px;
            font-size: 17px !important;
        }
    }
}

ion-button {
    letter-spacing: 1px;
    text-transform: capitalize;
}

ion-tab-bar {
    --background: white;
    width: 95%;
    border-radius: 10px;
    height: 60px;
    // display: block;
    // margin: auto;
}

ion-menu-button {
    color: #d3d3d3;
}

app-tabs {
    background: #1c1e2a;
}

ion-modal.transparent_modal {
    .modal-wrapper{
        background: rgba(0,0,0,0.7);
        // width: 100% !important;
        border-radius: 15px;
    }
}

@media screen and (max-width:414px){
    .main_content_div {
        width: 70%;
        min-height: 100%;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

}